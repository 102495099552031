<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.2 8.57">
    <g id="a" />
    <g id="b">
      <g id="c"><polyline class="d" points="15.73 .47 8.1 8.1 .47 .47" /></g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.d {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.95px;
}
</style>
