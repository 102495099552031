<template>
  <div
    class="bg-white rounded-md shadow-sm w-full pt-8 px-4"
    v-for="e in props.events"
    :key="e._id"
  >
    <div class="pb-8">
      <div class="pb-8 mx-8 border-b border-gray-4">
        <div class="font-medium">
          {{ format(new Date(e._id), "dd.MM.yyyy") }}
        </div>
      </div>
      <div
        id="scroll"
        class="max-h-[35vh] overflow-y-auto"
        style="direction: ltr"
      >
        <ul class="px-8" style="direction: rtl">
          <li
            v-for="event in e.events"
            :key="event._id"
            class="py-6 border-b border-gray-4 last:border-b-0"
          >
            <div class="flex justify-between items-center">
              <div class="flex gap-3 items-center">
                <WeddingIcon
                  v-if="event.type === 'wedding'"
                  class="h-8 w-auto"
                  alt=""
                />
                <img
                  v-else-if="event.type === 'birthday'"
                  src="@/assets/images/birthday.svg"
                  class="h-8 w-auto"
                  alt=""
                />
                <img
                  v-else-if="event.type === 'startToWork'"
                  src="@/assets/images/year.svg"
                  class="h-8 w-auto"
                  alt=""
                />
                <div class="name font-light">
                  <template v-if="event.type === 'wedding'">
                    יום נישואים {{ event.name }}
                  </template>
                  <template v-else-if="event.type === 'birthday'">
                    יום הולדת {{ event.name }}
                  </template>
                  <template v-else-if="event.type === 'startToWork'">
                    יום שנה בחברה {{ event.name }}
                  </template>
                </div>
              </div>
              <div class="flex items-center gap-4">
                <button
                  class="red-button text-sm"
                  type="button"
                  @click="sendBox(new Date(e._id), event)"
                >
                  שלח ארגז
                </button>
                <XIcon
                  class="w-3 h-3 fill-currentColor stroke-currentColor cursor-pointer"
                  @click="deleteItem(event._id)"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import useEventStore from "@/stores/events";

import format from "date-fns/format";

import XIcon from "../icons/xIcon.vue";
import WeddingIcon from "../icons/weddingIcon.vue";
import { useRouter } from "vue-router";

const props = defineProps(["events"]);

const eventStore = useEventStore();

const router = useRouter();

const sendBox = (date, data) => {
  router.push({
    name: "box",
    query: {
      data: JSON.stringify({ date: date.getTime(), moreData: data.employee }),
    },
  });
};

const { deleteItem } = eventStore;
</script>

<style lang="scss" scoped></style>
