import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "@/views/DashboardView.vue";
import useAuthStore from "@/stores/auth.js";
import DefaultLayout from "@/layouts/defaultLayout.vue";
import LoginLayout from "@/layouts/loginLayout.vue";

const routes = [
  // התחברות
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      middleware: "isGuest",
      layout: LoginLayout,
    },
  },
  // הרשמה
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/SignupView.vue"),
    meta: {
      middleware: "isGuest",
      layout: LoginLayout,
    },
  },
  // איפוס סיסמא
  {
    // ResetPassword
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      middleware: "isGuest",
      layout: LoginLayout,
    },
  },
  {
    path: "/reset-password/:id",
    name: "ResetPasswordToken",
    component: () => import("../views/ResetPasswordToken.vue"),
    meta: {
      middleware: "isGuest",
      layout: LoginLayout,
    },
  },
  // דאשבורד
  {
    path: "/",
    name: "Dashboard",
    component: DashboardView,
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
  },
  // לוח שנה
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("../views/Calendar/CalendarView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
  },
  // הגדרות
  {
    path: "/setting",
    name: "Setting",
    component: () => import("../views/User/MyUserView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
  },
  // רשימות
  {
    path: "/lists",
    name: "Lists",
    component: () => import("../views/lists/ListsView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
  },
  {
    path: "/lists/upload",
    name: "ListsUpload",
    component: () => import("../views/lists/ListUploadView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
  },
  {
    path: "/lists/:id",
    name: "EditList",
    component: () => import("../views/lists/EditListView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
  },
  // הזמנות
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/Orders/OrdersView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
      // minPadding: true,
    },
    children: [
      {
        path: "",
        name: "box",
        // component: () => import("../views/Orders/OrderBoxView.vue"),
        component: () => import("../views/Orders/NewOrderBoxView.vue"),
        meta: {
          middleware: "isAuth",
        },
        props: { newsletterPopup: false },
      },
    ],
  },
  // חשבוניות
  {
    path: "/invoices",
    name: "Invoices",
    component: () => import("../views/Invoices/InvoicesView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
    children: [
      {
        path: "",
        name: "InvoicesNeedToPay",
        component: () => import("../views/Invoices/InvoicesNeedToPay.vue"),
        meta: {
          middleware: "isAuth",
        },
      },
      {
        path: "notOayed",
        name: "InvoicesNotPayed",
        component: () => import("../views/Invoices/InvoicesNotPayed.vue"),
        meta: {
          middleware: "isAuth",
        },
      },
      {
        path: "payed",
        name: "InvoicesPayed",
        component: () => import("../views/Invoices/InvoicesPayed.vue"),
        meta: {
          middleware: "isAuth",
        },
      },
    ],
  },
  // מעקב הזמנות
  {
    path: "/trackOrders",
    name: "TrackOrder",
    component: () => import("../views/TrackOrder/TrackOrderView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
    children: [
      {
        path: "",
        name: "TrackAllOrders",
        component: () => import("../views/TrackOrder/AllOrders.vue"),
        meta: {
          middleware: "isAuth",
        },
      },
      {
        path: ":id",
        name: "TrackOneOrder",
        component: () => import("../views/TrackOrder/TrackOneOrder.vue"),
        meta: {
          middleware: "isAuth",
        },
      },
    ],
  },
  {
    path: "/orders/box/:id",
    name: "ViewBoxOrder",
    component: () => import("../views/Orders/BoxOrderView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
  },
  // יצירת קשר
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact/ContactView.vue"),
    meta: {
      middleware: "isAuth",
      layout: DefaultLayout,
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.middleware) {
    const { isAuth } = useAuthStore();

    if (to.meta.middleware === "isAuth") {
      if (!isAuth) return next({ name: "Login" });
    } else if (to.meta.middleware === "isGuest") {
      if (isAuth) return next({ name: "Dashboard" });
    }
  }

  return next();
});

export default router;
