<template>
  <section>
    <div class="flex">
      <!-- SIDE BAR -->
      <div
        class="fixed inset-0 z-[9997] bg-white bg-opacity-80"
        v-if="menuIsOpen"
        @click="menuIsOpen = false"
      />
      <div
        class="fixed lg:sticky lg:translate-x-0 flex flex-col h-screen top-0 w-64 transition-all"
        :class="{
          'top-0 bottom-0 right-0 z-[9999] border-l-2 border-giraffa-yellow shadow-xl':
            menuIsOpen,
          'translate-x-full': !menuIsOpen,
        }"
      >
        <div
          class="flex flex-col flex-grow justify-between bg-white overflow-y-auto"
        >
          <div
            class="flex items-center flex-shrink-0 px-4 py-8"
            @click="menuIsOpen = false"
          >
            <router-link to="/" class="mx-auto">
              <img class="h-8 w-auto" src="@/assets/logo.svg" alt="ג׳ירפה" />
            </router-link>
          </div>
          <div class="flex-1 flex flex-col">
            <nav class="flex-1 py-4 2xl:space-y-2" @click="menuIsOpen = false">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  route.path.startsWith(item.href)
                    ? 'text-giraffa-red border-l-8 border-giraffa-red bg-gray-1'
                    : 'text-gray-3 hover:text-giraffa-red',
                  'group flex items-center px-2 py-4 pr-8',
                ]"
              >
                <component
                  v-if="item.icon"
                  :is="item.icon"
                  class="ml-5 flex-shrink-0 h-8 w-8 fill-currentColor stroke-currentColor"
                  aria-hidden="true"
                />
                {{ item.name }}
              </router-link>
              <div
                class="text-giraffa-red group flex items-center px-2 py-4 pr-8 cursor-pointer hover:font-bold"
                @click="logout"
              >
                <LogoutIconVue
                  class="ml-5 flex-shrink-0 h-8 w-8 fill-currentColor stroke-currentColor"
                  aria-hidden="true"
                />
                התנתקות
              </div>
            </nav>
          </div>
          <div class="p-8">
            <a
              href="https://api.whatsapp.com/send?phone=972512233293"
              target="_blank"
            >
              <img
                src="@/assets/images/contact-us.png"
                alt=""
                class="hover:scale-105 transition-all object-contain w-full"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col flex-1 bg-gray-1 min-h-screen px-1 md:px-12 z-40"
      >
        <!-- Header Search & User -->
        <div class="sticky top-0 bg-gray-1 z-40 pt-4">
          <div class="border-b-2 border-giraffa-yellow py-4">
            <div class="flex-1 flex justify-between">
              <div class="flex-1 flex">
                <div class="lg:hidden" @click="menuIsOpen = !menuIsOpen">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 100 100"
                    class="menu"
                  >
                    <path
                      class="line line1"
                      d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                    />
                    <path class="line line2" d="M 20,50 H 80" />
                    <path
                      class="line line3"
                      d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                    />
                  </svg>
                </div>
                <!-- <form class="w-full flex">
                  <label for="search-field" class="sr-only">חיפוש</label>
                  <div class="relative">
                    <input
                      class="bg-transparent border-2 border-gray-4 rounded-lg px-3 py-2 placeholder:text-sm text-sm focus:outline-none"
                      placeholder="חיפוש"
                      type="search"
                      name="search"
                    />
                    <SearchIcon
                      class="text-gray-3 absolute left-3 h-6 top-[50%] -translate-y-[50%]"
                    />
                  </div>
                </form> -->
              </div>
              <!-- Notification && User -->
              <div class="flex items-center gap-4 relative">
                <div class="">
                  <NotificationIcon
                    :hasNotification="newMessages"
                    @click="openNotificationEvent"
                    class="h-6 cursor-pointer"
                  />
                  <Transition>
                    <div
                      v-if="openNotification"
                      class="fixed inset-0 bg-black bg-opacity-20 z-40 cursor-pointer"
                      @click="openNotification = false"
                    ></div>
                  </Transition>
                  <Transition>
                    <div
                      v-if="openNotification"
                      class="absolute bg-white left-10 top-10 w-60 rounded shadow z-50"
                    >
                      <DashboardMessages
                        :messages="messages"
                        :showTitle="false"
                      />
                    </div>
                  </Transition>
                </div>
                <div>
                  <router-link
                    :to="{ name: 'Setting' }"
                    class="flex items-center gap-2"
                  >
                    <DefaultUser
                      v-if="!profileImage"
                      class="h-6 cursor-pointer"
                    />
                    <img
                      v-else
                      :src="profileImage"
                      alt="profile image"
                      class="h-8 w-auto rounded-full shadow-sm"
                    />

                    <ArrowDown class="h-2 cursor-pointer" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Main page -->
        <main :class="route?.meta?.minPadding ? 'pt-4' : 'pt-10'">
          <slot></slot>
        </main>
      </div>
    </div>

    <div
      class="fixed transition-all duration-1000"
      :style="{
        right: showNotificationRight + 'vw',
        bottom: showNotification ? '-2rem' : '-50vh',
      }"
    >
      <div
        class="bg-giraffa-yellow rounded-full px-8 py-4 border-4 border-white shadow-xl mr-24 w-48"
      >
        <XIcon
          class="h-3 w-auto stroke-giraffa-red absolute left-0 top-0 cursor-pointer"
          @click="showNotification = false"
        />
        <h3 class="text-sm font-medium">הידעת?</h3>
        <p class="text-sm">הסיור בשוק התקווה הוא גם סיור כשר!</p>
      </div>
      <img src="@/assets/images/giraffa.png" class="w-40 -mt-12" alt="" />
    </div>
  </section>
</template>

<script setup>
import useAuthStore from "@/stores/auth";
import ordersIconVue from "../components/icons/ordersIcon.vue";
import invoicesIconVue from "../components/icons/invoiceIcon.vue";
import calendarIconVue from "../components/icons/calendarIcon.vue";
import trackerIconVue from "../components/icons/trackerIcon.vue";
import contactIconVue from "../components/icons/contactIcon.vue";
import NotificationIcon from "../components/icons/notificationIcon.vue";
import LogoutIconVue from "../components/icons/LogoutIcon.vue";
import { onMounted, ref, computed } from "vue";
import DefaultUser from "../components/icons/defaultUser.vue";
import ArrowDown from "../components/icons/arrowDown.vue";
// import SearchIcon from "../components/icons/searchIcon.vue";
import { useRoute, useRouter } from "vue-router";
import XIcon from "../components/icons/xIcon.vue";
import useMessageStore from "@/stores/messages";
import { storeToRefs } from "pinia";
import DashboardMessages from "../components/messages/DashboardMessages.vue";
import ListIconVue from "../components/icons/ListIcon.vue";

const messageStore = useMessageStore();
const { data: messages } = storeToRefs(messageStore);

onMounted(() => {
  messageStore.getData();
});

const openNotification = ref(false);
const newMessages = computed(() => messages.value.some((x) => !x.view));

const openNotificationEvent = () => {
  if (openNotification.value) {
    openNotification.value = false;
    return;
  }
  openNotification.value = true;
  messageStore.clearNew();
  //
};

const authStore = useAuthStore();

const { profileImage, logout: $LogOut } = authStore;

const navigation = [
  { name: "הזמנות", href: "/orders", icon: ordersIconVue },
  { name: "חשבוניות", href: "/invoices", icon: invoicesIconVue },
  { name: "מעקב הזמנות", href: "/trackOrders", icon: trackerIconVue },
  { name: "הרשימות שלי", href: "/lists", icon: ListIconVue },
  { name: "לוח שנה", href: "/calendar", icon: calendarIconVue },
  { name: "יצירת קשר", href: "/contact", icon: contactIconVue },
];

const route = useRoute();
const router = useRouter();

const showNotification = ref(false);
const showNotificationRight = ref(0);

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

async function logout() {
  await $LogOut();

  router.push({
    name: "Login",
  });
}

function openNotify() {
  if (showNotification.value === false) {
    showNotification.value = true;
    showNotificationRight.value = randomIntFromInterval(2, 80);
  }

  setTimeout(() => {
    openNotify();
  }, randomIntFromInterval(1, 5) * 1000 * 60);
}

onMounted(() => {
  setTimeout(() => {
    openNotify();
  }, 56000000000);
});

const menuIsOpen = ref(false);
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.menu {
  @apply bg-transparent border-none cursor-pointer flex p-0;
}
.line {
  fill: none;
  stroke: currentColor;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
</style>
