<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 31.26 31.26"
  >
    <g id="a" />
    <g id="b">
      <g id="c">
        <g>
          <circle class="f" cx="15.63" cy="15.63" r="15.63" />
          <g class="g">
            <g>
              <circle class="e" cx="15.63" cy="12.87" r="6.58" />
              <path
                class="e"
                d="M15.63,18.65c-7.31,0-13.24,5.93-13.24,13.24,0,.04,0,.07,0,.11H28.86s0-.07,0-.11c0-7.31-5.93-13.24-13.24-13.24Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.e {
  fill: #e52537;
}
.f {
  fill: #ff7ba8;
}
</style>
