import { defineStore } from "pinia";
import apiClient from "@/plugins/axios";
import { useCookies } from "@vueuse/integrations/useCookies";

const cookies = useCookies(["auth"]);

const store = defineStore("auth", {
  state: () => ({
    loading: false,
    user: {},
    lastFetch: null,
    error: null,
  }),
  getters: {
    authCookie: () => {
      return cookies.get("auth");
    },
    isAuth: (state) => !!(state.user && state.user._id),
    fullName: (state) => `${state.user.firstName} ${state.user.lastName}`,
    profileImage: () => ``,
  },
  actions: {
    setCookie(d) {
      cookies.set("auth", d._id, { sameSite: true });
    },
    async logout() {
      this.user = {};
      if (this.authCookie) {
        cookies.remove("auth", { sameSite: true });
        await apiClient.get("/auth/logout");
      }
    },
    async getMe() {
      console.log(this.authCookie);
      try {
        if (this.authCookie) {
          const { data } = await apiClient.get("/auth");
          if (data.success && data.data) {
            this.user = data.data;
            return true;
          }
        }
        return false;
      } catch (error) {
        this.logout();
        return false;
      } finally {
        this.lastFetch = Date.now();
      }
    },
    async login(email, password) {
      try {
        this.loading = true;
        const { data } = await apiClient.post("/auth/login/password", {
          email,
          password,
        });
        if (data.success) {
          this.setCookie(data.user);
          this.user = data.user;
        }
        return data;
      } catch (error) {
        this.error = error.message;
        return error.response.data;
      } finally {
        this.loading = false;
      }
    },
    async phoneSendOtp(phone) {
      try {
        this.loading = true;
        const { data } = await apiClient.post("/auth/login/phone/getCode", {
          phone,
        });
        return data;
      } catch (error) {
        this.error = error.message;
        return error.response.data;
      } finally {
        this.loading = false;
      }
    },
    async phoneLogin(phone, code) {
      try {
        this.loading = true;
        const { data } = await apiClient.post("/auth/login/phone/checkCode", {
          phone: phone,
          code: code,
        });
        if (data.success) {
          this.setCookie(data.user);
          this.user = data.user;
        }
        return data;
      } catch (error) {
        this.error = error.message;
        return error.response.data;
      } finally {
        this.loading = false;
      }
    },
  },
});

export default store;
