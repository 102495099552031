<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.46 27.46">
    <g id="a" />
    <g id="b">
      <g id="c">
        <g>
          <path
            class="e"
            d="M26.56,26.56l-2.02-6.97c1-1.8,1.57-3.88,1.57-6.08C26.1,6.54,20.46,.9,13.5,.9S.9,6.54,.9,13.5s5.64,12.6,12.6,12.6c2.72,0,5.23-.87,7.29-2.33l5.77,2.79Z"
          />
          <path class="d" d="M8.43,14.94c1.93,0,1.93-3,0-3s-1.93,3,0,3h0Z" />
          <path class="d" d="M14.29,14.94c1.93,0,1.93-3,0-3s-1.93,3,0,3h0Z" />
          <path class="d" d="M20.15,14.94c1.93,0,1.93-3,0-3s-1.93,3,0,3h0Z" />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.d {
}
.e {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.8px;
}
</style>
