<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.25 34.89">
    <g id="a" />
    <g id="b">
      <g id="c">
        <g id="d">
          <path
            class="e"
            d="M1,13.15c0-5.83,4.14-10.84,9.84-11.93,6.72-1.29,13.19,3.26,14.25,10.04,.38,2.42,.06,4.75-.98,6.98-1.38,2.95-3.1,5.7-4.97,8.37-1.68,2.39-3.47,4.7-5.36,6.92-.4,.47-.92,.49-1.31,.03-3.31-3.9-6.36-7.99-8.88-12.45-.75-1.33-1.48-2.68-1.98-4.13-.43-1.24-.62-2.52-.62-3.83Zm6.01,0c0,3.37,2.74,6.11,6.1,6.12,3.37,0,6.12-2.74,6.12-6.12,0-3.38-2.74-6.12-6.12-6.11-3.36,0-6.1,2.75-6.09,6.12Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.e {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
</style>
