<script setup>
import { RouterView, useRoute } from "vue-router";

const route = useRoute();
</script>

<template>
  <component :is="route.meta.layout" aria-hidden="true">
    <RouterView v-slot="{ Component }">
      <Transition
        :name="route.meta.transition || 'fast-fade'"
        mode="out-in"
        appear
      >
        <div :key="Component">
          <component :is="Component" />
        </div>
      </Transition>
    </RouterView>
  </component>
</template>

<style></style>
