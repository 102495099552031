<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.9 18.26">
    <g id="a" />
    <g id="b">
      <g id="c"><polyline class="d" points="9.52 17.88 .77 9.13 9.52 .38" /></g>
    </g>
  </svg>
</template>

<style scoped>
.d {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
  stroke-width: 1.09px;
}
</style>
