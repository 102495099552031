import { defineStore } from "pinia";
import apiClient from "@/plugins/axios";
import format from "date-fns/format";
import Swal from "sweetalert2";
import { toastFire } from "../utils/sweetalert";

const store = defineStore("events", {
  state: () => ({
    loading: false,
    data: [],
    lastFetch: null,
    error: null,
    form: {
      type: null,
      name: "",
      date: null,
      fullName: "",
      phone: "",
      requests: "",
      shipAddress: {
        place_id: "",
        city: "",
        address: "",
        country: "",
        streetNumber: "",
        formatted_address: "",
        location: {
          lat: null,
          lng: null,
        },
        price: null,
        distance: null,
        floor: null,
        apartment: null,
        shipRemarks: "",
      },
      shipRemarks: "",
    },
  }),
  getters: {
    getDashboardEvents: (state) => state.data.slice(0, 2),
    getDashboardEventsCount: (state) =>
      Object.values(state.data.slice(0, 2)).reduce(
        (accumulator, currentValue) => accumulator + currentValue.events.length,
        0
      ),
  },
  actions: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await apiClient.get("/events");
        if (data.success && data.data) {
          this.data = data.data;
          return true;
        }
        return false;
      } catch (error) {
        this.data = [];
        return false;
      } finally {
        this.lastFetch = Date.now();
        this.loading = false;
      }
    },
    async addEvent() {
      const { data } = await apiClient.post("/events", this.form);

      const eventIndex = this.data.findIndex(
        (x) => x._id === format(this.form.date, "yyyy-MM-dd")
      );

      if (eventIndex > -1) {
        this.data[eventIndex].events.push(data.data);
      } else {
        this.data.push({
          _id: format(this.form.date, "yyyy-MM-dd"),
          events: [data.data],
        });
      }

      this.form = {
        type: null,
        name: "",
        date: null,
        fullName: "",
        phone: "",
        requests: "",
        shipAddress: {
          place_id: "",
          city: "",
          address: "",
          country: "",
          streetNumber: "",
          formatted_address: "",
          location: {
            lat: null,
            lng: null,
          },
          price: null,
          distance: null,
          floor: null,
          apartment: null,
          shipRemarks: "",
        },
        shipRemarks: "",
      };
      toastFire("האירוע נוסף בהצלחה");
    },
    async deleteItem(id) {
      try {
        const del = await Swal.fire({
          title: "למחוק את האירוע?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "כן, מחקו את האירוע!",
          cancelButtonText: "ביטול",
        });
        if (del.isConfirmed) {
          this.loading = true;
          const { data } = await apiClient.delete("/events/" + id);
          if (data.success) {
            const formatDate = format(new Date(data.data.date), "yyyy-MM-dd");
            const i = this.data.findIndex((x) => x._id === formatDate);
            if (i > -1) {
              const ix = this.data[i].events.findIndex((x) => x._id === id);
              this.data[i].events.splice(ix, 1);
              if (this.data[i].events.length === 0) {
                this.data.splice(i, 1);
              }
            }
            toastFire("האירוע נמחק בהצלחה");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
});

export default store;
