<template>
  <LoadingComponent v-if="loading" />
  <div class="pageGrid" style="" v-else>
    <!-- first col -->
    <div class="pt-8 flex flex-col gap-8">
      <!-- hello there -->
      <div
        class="bg-giraffa-pink relative rounded-md flex items-center shadow-sm"
      >
        <img src="@/assets/images/giraffa.png" class="-mt-14" alt="" />
        <div class="text-white px-4">
          <h1 class="text-3xl font-heavitas text-right" style="direction: ltr">
            Hello There!
          </h1>
          <p class="text-sm 2xl:text-base">
            ברוכה הבאה למערכת המדהימה שלנו! פה תוכלי להזמין ארגזים, סיורים,
            סדנאות ולעקוב אחרי ההזמנות והתשלומים!
          </p>
        </div>
      </div>

      <!-- invoices -->
      <!-- <div class="bg-white relative rounded-md shadow-sm px-6 py-4">
        <div
          class="header flex justify-between w-full items-center border-b border-gray-4 pb-4"
        >
          <div class="font-medium">הזמנות לתשלום</div>
          <router-link
            :to="{ name: 'InvoicesAll' }"
            class="flex items-center gap-2 text-giraffa-red"
          >
            <div class="font-light text-sm">כל החשבוניות</div>
            <ArrowLeft class="h-3" />
          </router-link>
        </div>
        <ul class="pt-4">
          <li class="text-sm" v-if="orders.length <= 0">אין הזמנות לתשלום</li>
          <li
            v-else
            class="w-full flex justify-between items-center py-2 last:pb-0 border-b border-gray-4 last:border-b-0"
            v-for="item in orders"
            :key="item.id"
          >
            <div class="font-medium">
              הזמנה

              {{ item?.shortId || item._id }}
            </div>
            <button
              class="red-button"
              v-if="!item.paymentVerify"
              @click="getLinkToPay(item._id)"
            >
              לתשלום
            </button>
          </li>
        </ul>
      </div> -->

      <!-- advertising -->
      <div class="">
        <template v-if="banner">
          <a :href="banner.link" target="_blank">
            <img :src="banner.image" class="w-full" :alt="banner.title" />
          </a>
        </template>
      </div>
    </div>
    <!-- second col -->
    <div class="">
      <div class="flex justify-between items-center pb-2">
        <div class="">אירועים קרובים ({{ getEventsCount }})</div>
        <router-link
          :to="{ name: 'Calendar' }"
          class="text-giraffa-red hover:text-giraffa-yellow font-light flex items-center gap-3"
        >
          <span>ללוח השנה שלי</span>
          <ArrowLeft class="h-3 stroke-currentColor" />
        </router-link>
      </div>
      <div class="flex flex-col gap-8 w-full">
        <List v-if="events.length > 0" :events="events" />
        <div v-else class="bg-white rounded-md shadow-sm w-full p-8">
          לא נמצאו אירועים קרובים
        </div>
      </div>
    </div>
    <!-- third col -->
    <div class="">
      <DashboardMessages :messages="messages" />
      <div class="">
        <img src="@/assets/images/bike.svg" alt="" class="p-12" />
      </div>
    </div>
  </div>
</template>

<script setup>
import ArrowLeft from "../components/icons/arrowLeft.vue";
import List from "../components/calendar/list.vue";

import useEventStore from "@/stores/events";
import useMessageStore from "@/stores/messages";

import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import DashboardMessages from "../components/messages/DashboardMessages.vue";
import apiClient from "../plugins/axios";
import LoadingComponent from "../components/base/LoadingComponent.vue";

const eventStore = useEventStore();
const messageStore = useMessageStore();

const { data: messages } = storeToRefs(messageStore);

const { getDashboardEvents: events, getDashboardEventsCount: getEventsCount } =
  storeToRefs(eventStore);

// const orders = ref([]);
const banner = ref(null);
const loading = ref(true);

async function getOrders() {
  // try {
  //   const { data } = await apiClient.get(`/orders?type=0`);
  //   orders.value = data.data;
  // } catch (error) {
  //   console.log(error);
  // }
}

async function getBanners() {
  try {
    const { data } = await apiClient.get(`/banners`);
    banner.value = data.data;
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  loading.value = true;
  getBanners();
  messageStore.getData();
  eventStore.getData();
  getOrders();
  loading.value = false;
});

// const getLinkToPay = async (id) => {
//   const { data } = await apiClient.get(`/greenInvoice/payLink/${id}`);

//   window.open(
//     data.data,
//     "pay",
//     "toolbar=no, menubar=no, width=800, height=800"
//   );
// };
</script>

<style lang="scss" scoped>
.pageGrid {
  @apply grid;
  @apply gap-12;
  grid-template-columns: 1fr;

  @screen lg {
    @apply gap-10;
    grid-template-columns: 1.5fr 1.5fr 1fr;
  }

  @screen 2xl {
    @apply gap-16;
    grid-template-columns: 1.6fr 2fr 1.2fr;
  }
}
</style>
