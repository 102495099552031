<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.22 28.23">
    <g id="a" />
    <g id="b">
      <g id="c">
        <g>
          <rect
            class="d"
            x="1"
            y="4.36"
            width="26.22"
            height="22.87"
            rx="3.72"
            ry="3.72"
          />
          <g>
            <line class="d" x1="7.22" y1="1" x2="7.22" y2="8.06" />
            <line class="d" x1="21.01" y1="1" x2="21.01" y2="8.06" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<style scoped>
.d {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
</style>
