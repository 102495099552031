<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.73 31.28">
    <g id="a" />
    <g id="b">
      <g id="c">
        <path
          class="d"
          d="M.85,0H14.45c.24,0,.45,.1,.6,.25l8.46,8.46c.14,.22,.21,.32,.22,.57V30.43c0,.47-.38,.85-.85,.85H.85c-.47,0-.85-.38-.85-.85V.85C0,.38,.38,0,.85,0h0ZM5.06,14.8c-.47,0-.85-.38-.85-.85s.38-.85,.85-.85h13.6c.47,0,.85,.38,.85,.85s-.38,.85-.85,.85H5.06Zm0,5.81c-.47,0-.85-.38-.85-.85s.38-.85,.85-.85h13.6c.47,0,.85,.38,.85,.85s-.38,.85-.85,.85H5.06Zm0,5.81c-.47,0-.85-.38-.85-.85s.38-.85,.85-.85h13.6c.47,0,.85,.38,.85,.85s-.38,.85-.85,.85H5.06ZM22.04,10.13h-3.37c-1.39,0-2.66-.57-3.58-1.49-.92-.92-1.49-2.18-1.49-3.58V1.7H1.7V29.58H22.04V10.13Zm-3.37-1.7h2.17L15.3,2.9v2.17c0,.93,.38,1.77,.99,2.38,.61,.61,1.45,.99,2.38,.99h0Z"
        />
      </g>
    </g>
  </svg>
</template>
<style scoped></style>
