<template>
  <div class="relative">
    <div class="flex absolute top-0 right-0" v-if="hasNotification">
      <span
        class="animate-ping absolute h-4 w-4 inline-flex rounded-full bg-giraffa-red opacity-75 duration-1000"
      ></span>
      <span
        class="relative inline-flex rounded-full h-4 w-4 bg-giraffa-red"
      ></span>
    </div>
    <div class="w-full h-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.96 25.12"
        class="w-full h-full"
      >
        <g id="a" />
        <g id="b">
          <g id="c">
            <g>
              <path
                d="M20.01,21.06l-2.43-5.54v-4.44c0-3.5-2.4-6.45-5.64-7.3,.29-.39,.47-.88,.47-1.4,0-1.31-1.07-2.38-2.37-2.38s-2.37,1.07-2.37,2.38c0,.53,.18,1.01,.47,1.4-3.24,.85-5.64,3.79-5.64,7.3v4.44L.05,21.06c-.08,.19-.06,.41,.05,.58,.11,.17,.31,.28,.51,.28H6.27c.29,1.81,1.87,3.2,3.76,3.2s3.46-1.39,3.76-3.2h5.66c.21,0,.4-.1,.51-.28,.11-.17,.13-.39,.05-.58h0ZM10.03,1.23c.63,0,1.15,.51,1.15,1.15s-.51,1.15-1.15,1.15-1.15-.51-1.15-1.15c0-.63,.51-1.15,1.15-1.15Zm0,22.66c-1.21,0-2.22-.84-2.5-1.97h5c-.28,1.13-1.29,1.97-2.5,1.97h0ZM1.55,20.69l2.1-4.8c.03-.08,.05-.16,.05-.25v-4.57c0-3.49,2.84-6.32,6.32-6.32s6.32,2.84,6.32,6.32v4.57c0,.08,.02,.17,.05,.25l2.1,4.8H1.55Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasNotification: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.d {
  fill: #e52537;
}
</style>
