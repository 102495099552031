<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.19 18.19">
    <g id="a" />
    <g id="b">
      <g id="c">
        <g>
          <line class="d" x1="1" y1="1" x2="17.19" y2="17.19" />
          <line class="d" x1="17.19" y1="1" x2="1" y2="17.19" />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.d {
  stroke-width: 2px;
}
</style>
