<template>
  <div class="pb-2" v-if="showTitle">
    <div class="">הודעות ({{ messages.length }})</div>
  </div>
  <div
    class="bg-white rounded-md shadow-sm"
    :class="{ ' max-h-[80vh] overflow-auto': !showTitle }"
  >
    <ol class="px-8 py-4" v-if="messages.length > 0">
      <li
        v-for="(message, i) in messages"
        :key="i"
        class="py-6 border-b border-gray-4 last:border-b-0 flex flex-col gap-1 relative"
      >
        <div>{{ message?.title }}</div>
        <div class="font-light">{{ message?.message }}</div>
        <div
          class="absolute top-1/2 -translate-x-1/2 left-0"
          v-if="message.type === 'BALANCE_NOTIFICATION'"
        >
          <XIcon
            class="h-3 stroke-giraffa-red cursor-pointer"
            @click="deleteMessage(message._id, i)"
          />
        </div>

        <div
          class="text-giraffa-red flex items-center gap-2"
          v-if="
            message.type === 'TOUR_CONFIRM' ||
            message.type === 'WORKSHOP_CONFIRM'
          "
        >
          <router-link
            :to="{ name: 'PayTour', params: { id: message.referenceId } }"
          >
            לסיום הרכישה
          </router-link>
          <ArrowLeft class="h-3" />
        </div>
      </li>
    </ol>
    <div v-else class="px-8 py-4 text-gray-3">אין הודעות</div>
  </div>
</template>

<script setup>
import { toRefs } from "vue";
import apiClient from "../../plugins/axios";
import ArrowLeft from "../icons/arrowLeft.vue";
import XIcon from "../icons/xIcon.vue";
import useMessageStore from "@/stores/messages";

const props = defineProps({
  messages: Array,
  showTitle: { type: Boolean, default: true },
});

const { messages, showTitle } = toRefs(props);

const messageStore = useMessageStore();

async function deleteMessage(id, index) {
  console.log(index);
  await apiClient.delete("/messages/" + id);
  messageStore.deleteOne(index);
}
</script>

<style lang="scss" scoped></style>
