<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.07 40.07">
    <g id="a" />
    <g id="b">
      <g id="c">
        <g>
          <circle class="f" cx="20.04" cy="20.04" r="20.04" />
          <circle class="e" cx="20.04" cy="20.04" r="20.04" />
          <path
            class="g"
            d="M19.1,12.41c-1.91,.21-3.68,1.06-5.04,2.42-1.59,1.59-2.48,3.73-2.48,5.98s.89,4.39,2.48,5.98c1.59,1.59,3.73,2.48,5.98,2.48s4.39-.89,5.98-2.48c1.59-1.59,2.48-3.73,2.48-5.98s-.89-4.39-2.48-5.98c-1.36-1.36-3.14-2.22-5.04-2.42l2.38-2.84c2.18,.64,4.14,1.91,5.62,3.66,1.8,2.12,2.78,4.79,2.78,7.58,0,3.12-1.23,6.08-3.43,8.28-2.2,2.2-5.17,3.43-8.28,3.43s-6.08-1.23-8.28-3.43c-2.2-2.2-3.43-5.17-3.43-8.28,0-2.78,.98-5.45,2.78-7.58,1.48-1.75,3.44-3.02,5.62-3.66l2.38,2.84Z"
          />
          <path
            class="d"
            d="M22.78,6.29h-5.49l-1.12,1.49,3.86,4.61,3.86-4.61-1.12-1.49Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.d {
  fill: #fff;
}
.e {
  fill: #e52537;
}
.f,
.g {
  fill: #fdc30b;
}
.g {
  fill-rule: evenodd;
}
</style>
