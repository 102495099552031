<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 h-screen">
    <div class="baseCell bg-giraffa-red order-2 lg:order-1">
      <img src="@/assets/login-logo.svg" alt="" class="px-10" />
    </div>
    <div class="baseCell flex-col order-1 lg:order-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.baseCell {
  /* @apply h-full; */
  @apply flex items-center justify-center px-2 lg:px-8;
}
</style>
