import { createApp, markRaw } from "vue";
import { createPinia, storeToRefs } from "pinia";

import VueGoogleMaps from "@fawmi/vue-google-maps";

import App from "./App.vue";
import router from "./router";

import useAuthStore from "./stores/auth";

import "./assets/main.scss";
import "sweetalert2/dist/sweetalert2.min.css";

async function initApp() {
  const app = createApp(App);
  const pinia = createPinia();
  app.use(pinia);

  const userStore = useAuthStore();
  await userStore.getMe();

  pinia.use(({ store }) => {
    store.$router = markRaw(router);
  });

  const user = storeToRefs(userStore);
  app.config.globalProperties.$user = user.user;

  app.provide("vat", 17);

  app.config.globalProperties.$global = {
    vat: 17,
    currencySymbol: " ש״ח",
  };

  app.config.globalProperties.$filters = {
    number(value) {
      return new Intl.NumberFormat("he-IL").format(value);
    },
    currency(value) {
      return (
        this.number(value) + app.config.globalProperties.$global.currencySymbol
      );
    },
    onlyVat(value) {
      const vat = app.config.globalProperties.$global.vat;
      const onlyVat = (Number(value) / (vat + 100)) * 17;
      return Math.round((onlyVat + Number.EPSILON) * 100) / 100;
    },
    withoutVat(value) {
      const val = value - this.onlyVat(value);
      return Math.round((val + Number.EPSILON) * 100) / 100;
    },
    currencyWithoutVat(value) {
      return (
        this.number(this.withoutVat(value)) +
        app.config.globalProperties.$global.currencySymbol
      );
    },
    currencyOnlyVat(value) {
      return (
        this.number(this.onlyVat(value)) +
        app.config.globalProperties.$global.currencySymbol
      );
    },
    getOrderType(value) {
      switch (value) {
        case "box":
          return "ארגז";
        case "tour":
          return "סיור";
        case "workshop":
          return "סדנא";
        default:
          return `-`;
      }
    },
  };

  app.use(router);

  app.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyA7ODMcASZgI6kYunWCz5u28S4EpHpSYDQ",
      language: "he",
    },
  });

  app.mount("#app");
}
initApp();
