import { defineStore } from "pinia";
import apiClient from "@/plugins/axios";
import differenceInMinutes from "date-fns/differenceInMinutes";

const store = defineStore("messages", {
  state: () => ({
    loading: false,
    data: [],
    lastFetch: null,
    error: null,
    fetchAgain: 1,
  }),
  actions: {
    async clearNew() {
      if (this.data.length > 0) {
        try {
          const newMessagesList = [];
          const _n = this.data.some((x) => !x.view);

          this.data = this.data.map((x) => {
            newMessagesList.push(x._id);
            return { ...x, view: true };
          });

          if (newMessagesList.length > 0 && _n) {
            await apiClient.post("/messages/markView", newMessagesList);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getData() {
      if (!this.loading) {
        if (
          this.data.length <= 0 ||
          !this.lastFetch ||
          differenceInMinutes(Date.now(), this.lastFetch) > this.fetchAgain
        ) {
          try {
            this.loading = true;
            const { data } = await apiClient.get("/messages");
            if (data.success && data.data) {
              this.data = data.data;
              return true;
            }
            return false;
          } catch (error) {
            this.data = [];
            return false;
          } finally {
            this.lastFetch = Date.now();
            this.loading = false;
          }
        }
      }
    },
    deleteOne(index) {
      this.data.splice(index, 1);
    },
  },
});

export default store;
